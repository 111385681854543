<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>결제카드를 변경하시겠습니까?</h3>
            <div class="single-file-btn">
                <p @click="$emit('closeChange')">아니오</p>
                <!-- <p id="color-text" @click="[$emit('closeChange'), $emit('showChanged')]">네</p> -->
                <p id="color-text" @click="getCardRegist()">네</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'changeCard',
  methods: {
    getCardRegist () {
      this.$emit('closeChange');
      this.$emit('getCardRegist');
    }
  }
};
</script>
