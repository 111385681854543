<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>
                <p>현재 무료로 이용중인 스페이스가 있습니다.</p>
                <p>무료 기간 이후 해당 카드로 자동 결제되는 것에 동의하십니까?</p>
            </h3>
            <div class="single-file-btn">
                <p @click="updatePayStatus('N')">아니오</p>
                <p id="color-text" @click="updatePayStatus('Y')">네</p>
            </div>
        </div>
    </div>
</template>

<script>
import VueCookies from "vue-cookies";
import axios from 'axios';

export default {
  props: {
    spaceList: Array
  },

  methods: {
    // 무료 이용 스페이스 정기 결제 여부 업데이트
    updatePayStatus (val) {
      const userId = VueCookies.get("userId");
      const spaceIdList = this.spaceList;
      const paymentStatus = val;
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      console.log('spaceIdList', spaceIdList);

      axios
        .post('/api/product/updatePaymentStatus', { userId, spaceIdList, paymentStatus }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.$emit('closePopup');
          }
        })
        .catch(e => { console.log(e); });
    }
  }
};
</script>

<style scoped>

</style>
