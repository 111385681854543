<template>
    <div class="wrap" style="padding-bottom: 100px">
        <form
            name="payForm"
            action="https://devpg.bluewalnut.co.kr/bill/hpayBillLeading.jsp"
            method="post"
            v-if="mode === 'dev'"
        >
            <input type="hidden" name="PayMethod" value="CARD" />
            <input type="hidden" id="EncryptData" name="EncryptData" value="" />
            <input type="hidden" id="EdiDate" name="EdiDate" value="" />
            <input type="hidden" name="merchantKey" id="merchantKey" value="" />
            <input type="hidden" id="MID" name="MID" value="" />
            <input type="hidden" id="BuyerAuthNum" name="BuyerAuthNum" value="" />
            <input type="hidden" id="MallUserID" name="MallUserID" value="" />
            <input type="hidden" id="MerchantDt" name="MerchantDt" value="" />
            <input type="hidden" id="RedirectUrl" name="RedirectUrl" value="" />
            <input type="hidden" id="UsePopup" name="UsePopup" value="true" />
        </form>
        <form name="payForm" action="https://pg.bluewalnut.co.kr/bill/hpayBillLeading.jsp" method="post" v-else>
            <input type="hidden" name="PayMethod" value="CARD" />
            <input type="hidden" id="EncryptData" name="EncryptData" value="" />
            <input type="hidden" id="EdiDate" name="EdiDate" value="" />
            <input type="hidden" name="merchantKey" id="merchantKey" value="" />
            <input type="hidden" id="MID" name="MID" value="" />
            <input type="hidden" id="BuyerAuthNum" name="BuyerAuthNum" value="" />
            <input type="hidden" id="MallUserID" name="MallUserID" value="" />
            <input type="hidden" id="MerchantDt" name="MerchantDt" value="" />
            <input type="hidden" id="RedirectUrl" name="RedirectUrl" value="" />
            <input type="hidden" id="UsePopup" name="UsePopup" value="true" />
        </form>
        <ChangeCard
            v-if="ShowChange"
            @closeChange="ShowChange = false"
            @showChanged="ShowChanged = true"
            @getCardRegist="cardRegist()"
        />
        <ChangedCard
            id="modalCheck"
            v-if="ShowChanged"
            :registered="registered"
            @closeChanged="ShowChanged = false"
            @getCardInfo="getCardInfo()"
        />
        <!-- <EventModal v-if="isEventModal" @closeModal="isEventModal = false"/> -->
        <Recurring v-if="popupStatus" :spaceList="spaceList" @closePopup="closePopup" />
        <Aside v-if="asideStatus" @closeAside="asideStatus = false" />
        <div class="space_pc">
            <section class="main payment">
                <div class="main_box" v-if="cardInfo !== null">
                    <div class="payment_title">
                        <h1>결제카드</h1>
                    </div>

                    <div class="payment_content card_up">
                        <div class="card_upload upload">
                            <div>
                                <ul class="card_name">
                                    <li>{{ cardInfo.cardName }}</li>
                                    <li>{{ cardInfo.cardKindCd }}카드</li>
                                </ul>
                                <!-- <ul class="card_number">
                    <li>{{ cardInfo.cardKindBin.substr(0, 4) }}</li>
                    <li>{{ cardInfo.cardKindBin.substr(4) }}<span></span><span></span></li>
                    <li><span></span><span></span><span></span><span></span></li>
                    <li><span></span><span></span><span></span><span></span></li>
                </ul> -->
                                <ul class="card_number">
                                    <li>{{ cardInfo.cardKindBin.substr(0, 4) }}</li>
                                    - &nbsp;
                                    <li>{{ cardInfo.cardKindBin.substr(4) }}**</li>
                                    - &nbsp;
                                    <li>****</li>
                                    - &nbsp;
                                    <li>****</li>
                                </ul>
                            </div>
                            <button id="card_change" @click="ShowChange = true">
                                <img src="/media/img/card_upload_icon.png" alt="" />
                                <p style="margin-bottom: 0px">카드변경</p>
                            </button>
                        </div>
                    </div>
                    <div class="regi_bottom">
                        <h5 style="margin-bottom: 0px">유의사항</h5>
                        <ul style="padding-left:0px;">
                            <li>
                                <span></span>
                                <p style="margin-bottom: 0px">
                                    본인 명의의 카드만 등록 가능합니다.
                                </p>
                            </li>
                            <li>
                                <span></span>
                                <p style="margin-bottom: 0px">
                                    제휴 카드의 경우, 제휴된 카드사명이 노출될 수 있습니다.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- 카드등록 안한경우 -->
                <div class="main_box" v-else>
                    <div class="payment_title">
                        <h1>결제카드</h1>
                    </div>
                    <!-- <div class="event_banner" v-if="isEventBanner" @click="linkEventPage()">
            <img src="/media/img/event/pc_payment_banner_1200_110.png" />
          </div> -->
                    <div class="payment_content">
                        <div class="card_upload" @click="cardRegist()">
                            <img src="/media/img/card_upload.png" alt="" />
                            <p style="margin-bottom:0px;">결제카드 등록하기</p>
                        </div>
                    </div>
                    <div class="regi_bottom">
                        <h5 style="margin-bottom: 0px">유의사항</h5>
                        <ul style="padding-left:0px;">
                            <li>
                                <span></span>
                                <p style="margin-bottom: 0px">
                                    본인 명의의 카드만 등록 가능합니다.
                                </p>
                            </li>
                            <li>
                                <span></span>
                                <p style="margin-bottom: 0px">
                                    제휴 카드의 경우, 제휴된 카드사명이 노출될 수 있습니다.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 -->
        <div class="space_mo">
            <header class="header mo_header">
                <div class="header_box">
                    <div class="logo">
                        <a @click="linkPlayer()">
                            <img src="/media/images/icon_back_24.svg" />
                            <h2 style="margin-bottom:0px;">결제카드</h2>
                        </a>
                    </div>
                    <div class="right_menu">
                        <div class="alarm_box">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                @click="mobileAlarm"
                            >
                                <path
                                    d="M9.02759 19.7773C9.02759 20.5181 9.46002 21.9996 11.1897 21.9996C12.9195 21.9996 13.3519 20.5181 13.3519 19.7773"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M2.00024 18.666H20.3786"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M3.62183 18.1113V10.3335C3.62183 7.92613 5.13534 3.11133 11.1894 3.11133C17.2434 3.11133 18.7569 8.2965 18.7569 10.8891V18.1113"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <ellipse
                                    v-if="$store.state.noticeCount !== 0"
                                    cx="17.6756"
                                    cy="6.44444"
                                    rx="4.32431"
                                    ry="4.44444"
                                    fill="#FF1150"
                                />
                            </svg>
                        </div>
                        <div class="toggle">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                @click="showAside"
                            >
                                <g clip-path="url(#clip0_2231_20993)">
                                    <path
                                        d="M24 20L6 20L6 18L24 18L24 20ZM24 13L12 13L12 11L24 11L24 13ZM24 6L6 6L6 4L24 4L24 6Z"
                                        fill="white"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2231_20993">
                                        <rect width="24" height="24" fill="white" transform="translate(24 24) rotate(180)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
            </header>
            <section class="main payment">
                <div class="main_box" v-if="cardInfo !== null">
                    <div class="payment_title">
                        <h1>결제카드</h1>
                    </div>
                    <div class="payment_content card_up">
                        <div class="card_upload upload">
                            <div>
                                <ul class="card_name" style="margin-bottom: 8px; padding-left: 0px">
                                    <li>{{ cardInfo.cardName }}</li>
                                    <li>{{ cardInfo.cardKindCd }}카드</li>
                                </ul>
                                <!-- <ul class="card_number">
                    <li>{{ cardInfo.cardKindBin.substr(0, 4) }}</li>
                    <li>{{ cardInfo.cardKindBin.substr(4) }}<span></span><span></span></li>
                    <li><span></span><span></span><span></span><span></span></li>
                    <li><span></span><span></span><span></span><span></span></li>
                </ul> -->
                                <ul class="card_number">
                                    <li>{{ cardInfo.cardKindBin.substr(0, 4) }}</li>
                                    - &nbsp;
                                    <li>{{ cardInfo.cardKindBin.substr(4) }}**</li>
                                    - &nbsp;
                                    <li>****</li>
                                    - &nbsp;
                                    <li>****</li>
                                </ul>
                            </div>
                            <button id="card_change" style="margin-top:42px;" @click="ShowChange = true">
                                <img src="/media/img/card_upload_icon.png" alt="" />
                                <p style="margin-bottom: 0px">카드변경</p>
                            </button>
                        </div>
                    </div>
                    <div class="regi_bottom">
                        <h5 style="margin-bottom: 0px">유의사항</h5>
                        <ul style="padding-left:0px;">
                            <li>
                                <span></span>
                                <p style="margin-bottom: 0px">
                                    본인 명의의 카드만 등록 가능합니다.
                                </p>
                            </li>
                            <li>
                                <span></span>
                                <p style="margin-bottom: 0px">
                                    제휴 카드의 경우, 제휴된 카드사명이 노출될 수 있습니다.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- 카드등록 안한경우 -->
                <div class="main_box" v-else>
                    <div class="payment_title">
                        <h1>결제카드</h1>
                    </div>
                    <!-- <div class="event_banner" v-if="isEventBanner && eventBanner" style="padding: 0 5%;" @click="linkEventPage()">
            <img src="/media/img/event/m_payment_banner_656_180.png" />
          </div> -->
                    <div class="payment_content" style="padding: 0 5%;">
                        <div class="card_upload" @click="cardRegist()">
                            <img src="/media/img/card_upload.png" alt="" />
                            <p style="margin-bottom:0px;">결제카드 등록하기</p>
                        </div>
                    </div>
                    <div class="regi_bottom">
                        <h5 style="margin-bottom: 0px">유의사항</h5>
                        <ul style="padding-left:0px;">
                            <li>
                                <span></span>
                                <p style="margin-bottom: 0px">
                                    본인 명의의 카드만 등록 가능합니다.
                                </p>
                            </li>
                            <li>
                                <span></span>
                                <p style="margin-bottom: 0px">
                                    제휴 카드의 경우, 제휴된 카드사명이 노출될 수 있습니다.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import ChangeCard from '@/components/modal/Card/ChangeCard.vue';
import ChangedCard from '@/components/modal/Card/ChangedCard.vue';
// import EventModal from "@/components/modal/Card/EventCard.vue";
import Recurring from '@/components/modal/Card/Recurring.vue';
import Aside from '@/layout/aside/Aside.vue';
import VueCookies from 'vue-cookies';
import router from '@/router';
import axios from 'axios';
import $ from 'jquery';

export default defineComponent({
  data () {
    return {
      ShowChange: false,
      ShowChanged: false,
      isEventModal: false,
      isEventBanner: false,
      cardInfo: null,
      asideStatus: false,
      mode: process.env.VUE_APP_APLAYZ,
      eventBanner: false,
      registered: false,
      // 정기 결제 여부 선택 팝업
      popupStatus: false,
      spaceList: []
    };
  },

  components: {
    ChangeCard,
    ChangedCard,
    // EventModal,
    Recurring,
    Aside
  },

  methods: {
    checkDday () {
      const dDay = new Date('2022-08-01 00:00:00');
      const today = new Date();
      today > dDay ? (this.eventBanner = false) : (this.eventBanner = true);
    },
    linkPlayer () {
      const spaceId = VueCookies.get('spaceId');
      if (spaceId != null) {
        router.push({ name: 'Player' });
      } else {
        router.push({ name: 'Main' });
      }
    },
    clickPre () {
      history.back();
    },
    linkEventPage () {
      this.$router.push({ name: 'Event' });
    },

    // 카드 등록 완료
    getShowChanged () {
      // 무료 이용 스페이스 확인
      const userId = VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      axios.post('/api/product/getFreeServiceSpace', { userId }, { headers }).then(res => {
        if (res.data.resultCd === '0000') {
          if (res.data.result != null && res.data.result.length > 0) {
            this.spaceList = res.data.result;
            this.popupStatus = true;
          } else {
            this.$gatag("add_card");
            window.fbq('track', 'add_card', {});
            this.ShowChanged = true;
          }
        }
      });
    },
    async getCardInfo () {
      const userId = VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      await axios
        .post('/api/product/getMyCardInfo', { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.cardInfo = res.data.result;
            this.cardInfo === null ? (this.registered = false) : (this.registered = true);
            axios
              .post('/api/product/checkCi', { userId }, { headers })
              .then(response => {
                if (response.data.resultCd === '0000') {
                  this.isEventBanner = response.data.result;
                }
              })
              .catch(error => {
                console.log(error);
              });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    cardRegist () {
      /**
       * pg사인 블루월넛에 요청하여 카드등록을 위임
       * requestRegisterCardToPG
       */
      // console.log("1");
      const popupX = window.screen.width / 2 - 400 / 2; // eslint-disable-line no-unused-vars
      const popupY = window.screen.height / 2 - 685 / 2; // eslint-disable-line no-unused-vars
      // eslint-disable-next-line no-unused-vars
      const windowReference = window.open(
        '',
        'popWin',
        'width=400,height=685, left=' + popupX + ',top=' + popupY + ',screenX=' + popupX + ',screenY=' + popupY
      ); // eslint-disable-line no-unused-vars
      const userId = VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      axios
        .post('/api/product/payKeyReturn', { userId }, { headers })
        .then(res => {
          const result = res.data.result;
          $('#merchantKey').val(result.merchantKey);
          $('#MID').val(result.mid);
          $('#RedirectUrl').val(result.redirectUrl);
          $('#BuyerAuthNum').val(result.birthDate);
          $('#EncryptData').val(result.encryptData);
          $('#EdiDate').val(result.ediDate);
          $('#MallUserID').val(result.mallUserId);

          const payForm = document.payForm;
          if ($('#UsePopup').val() === 'true') {
            payForm.target = 'popWin';
            payForm.method = 'post';
            payForm.submit();
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    showAside () {
      this.asideStatus = true;
    },
    mobileAlarm () {
      this.$router.push({ name: 'Notice0' });
    },
    closePopup () {
      this.popupStatus = false;
      this.getCardInfo();
    },
    messageEvent (event) {
      if (event.data.msg === 'hello world') {
        this.getShowChanged();
      }
    }
  },
  created () {
    this.getCardInfo();
  },
  mounted () {
    this.checkDday();
    window.addEventListener('message', this.messageEvent, false);
  },
  destroyed () {
    window.removeEventListener('message', this.messageEvent);
  }
});
</script>

<style scoped>
.card_upload .card_number {
  padding-left: 0px;
  margin-bottom: 0px;
}

/* .card_upload .card_number, .card_upload .card_name {
    height: 24px;
  } */
@media all and (max-width: 500px) {
  .card_upload .card_number,
  .card_upload .card_name {
    height: 20px;
  }
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
